<template>
  <div class="page">
    <Breadcrumbs/>
    <div class="page-container block-container">
      <div class="column left-block large-block">
        <h1>Privacy Policy</h1>
        <h2>Privacy Policy</h2>
        <p>Hosting on Demand heeft privacy hoog in het vaandel staan. We gaan met uw gegevens om zoals we dat ook graag zouden zien bij onze eigen gegevens. Dat betekent dat we handelen conform de Wet Bescherming Persoonsgegevens (WBP) en dat uw gegevens bij ons in vertrouwde handen zijn. We maken gebruik van cookies, ze zijn beschikbaar in 4 verschillende smaken.</p>
        <h3>Uw gegevens vertrouwd in onze handen</h3>
        <p>We verzamelen uitsluitend gegevens die belangrijk zijn voor het aanbod van onze diensten. We verkopen of verhuren nooit gegevens voor marketingdoeleinden, omdat we dat zelf ook niet op prijs zouden stellen.</p>
        <p>We gebruiken uw gegevens uitsluitend voor een zo optimaal mogelijke dienstverlening. Daarnaast gebruiken we uw gegevens voor in-house marketingactiviteiten, net als in samenwerking met onze eigen toeleveranciers. Uw gegevens zijn bij ons in goede handen, we garanderen een uitstekende beveiliging en gaan daar op een vertrouwde manier mee om.</p>
        <h3>Cookies: in 4 smaken</h3>
        <p>Bij Hosting on Demand gebruiken we cookies, beschikbaar in 4 verschillende smaken:</p>
        <ul>
          <li><strong>Gebruikscookies</strong><br>Uw gebruik van onze website levert interessante inzichten op. We analyseren (geanonimiseerde) gegevens, om de website zo optimaal mogelijk in te richten. We gebruiken de gebruikscookies van Google Analytics, zodat u een prettige website kunt gebruiken om uw account te beheren of nieuwe diensten te bestellen. We houden het aantal bezoekers op onze pagina’s bij, net als de tijdsduur die zij daar doorbrengen en de volgorde waarin u pagina’s bezoekt.</li>
          <li><strong>Gedragsafhankelijke inhoudscookies</strong><br>We analyseren het gedrag van onze verschillende klantgroepen door middel van gedragsafhankelijke inhoudscookies. Op basis daarvan registreren we websitebezoeken en maken we inschattingen van interesses, om de opbouw en inhoud van onze specifieke pagina’s en de website als geheel daar zo goed mogelijk op af te kunnen stemmen. Dit geldt zowel voor de inhoud op de website als de advertenties die we vertonen, op basis van het vertoonde surfgedrag.</li>
          <li><strong>Social media-cookies</strong><br>We bieden de mogelijkheid om artikelen bij ons op de website te delen via sociale media zoals Facebook, Twitter, LinkedIn en Google Plus. Die websites maken daarvoor gebruik van cookies, die we verzamelen om de optimale functionaliteiten te kunnen bieden.</li>
          <li><strong>Functionele cookies</strong><br>Dankzij een aantal functionele cookies bij ons op de website kunnen we de website optimaal laten functioneren. Dat betekent dat we ingevulde informatie tijdens het bestellen of uit het contactformulier onthouden en dat we voorkeuren over een taal en locatie kunnen opslaan. Bovendien zorgen we voor de opslag van gewenst instellingen voor videoweergaven en bijvoorbeeld de schermresolutie, zodat het niet nodig is om dat iedere keer opnieuw aan te geven.</li>
        </ul>
        <p>In algemene zin hechten we veel waarde aan privacy, dus gaan we met uw gegevens om zoals we dat ook graag zouden zien bij onze eigen gegevens. Uw gegevens zijn bij ons in goede handen, omdat we die nooit en op geen enkele manier verkopen of verhuren aan derde partijen.</p>
        <p>&nbsp;</p>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs.vue';

export default {
  name: 'PrivacyPolicy',
  components: {
    Breadcrumbs,
  },
}
</script>